export const state = () => ({
	timeZones: [
		{
			"name": "(GMT-11:00) Midway Island",
			"timezone": "Pacific/Midway"
		}, {
			"name": "(GMT-11:00) Samoa",
			"timezone": "US/Samoa"
		}, {
			"name": "(GMT-10:00) Hawaii",
			"timezone": "US/Hawaii"
		}, {
			"name": "(GMT-09:00) Alaska",
			"timezone": "US/Alaska"
		}, {
			"name": "(GMT-08:00) Pacific Time (US & Canada)",
			"timezone": "US/Pacific"
		}, {
			"name": "(GMT-08:00) Tijuana",
			"timezone": "America/Tijuana"
		}, {
			"name": "(GMT-07:00) Arizona",
			"timezone": "US/Arizona"
		}, {
			"name": "(GMT-07:00) Mountain Time (US & Canada)",
			"timezone": "US/Mountain"
		}, {
			"name": "(GMT-07:00) Chihuahua",
			"timezone": "America/Chihuahua"
		}, {
			"name": "(GMT-07:00) Mazatlan",
			"timezone": "America/Mazatlan"
		}, {
			"name": "(GMT-06:00) Mexico City",
			"timezone": "America/Mexico_City"
		}, {
			"name": "(GMT-06:00) Monterrey",
			"timezone": "America/Monterrey"
		}, {
			"name": "(GMT-06:00) Saskatchewan",
			"timezone": "Canada/Saskatchewan"
		}, {
			"name": "(GMT-06:00) Central Time (US & Canada)",
			"timezone": "US/Central"
		}, {
			"name": "(GMT-05:00) Eastern Time (US & Canada)",
			"timezone": "US/Eastern"
		}, {
			"name": "(GMT-05:00) Indiana (East)",
			"timezone": "US/East-Indiana"
		}, {
			"name": "(GMT-05:00) Bogota",
			"timezone": "America/Bogota"
		}, {
			"name": "(GMT-05:00) Lima",
			"timezone": "America/Lima"
		}, {
			"name": "(GMT-04:30) Caracas",
			"timezone": "America/Caracas"
		}, {
			"name": "(GMT-04:00) Atlantic Time (Canada)",
			"timezone": "Canada/Atlantic"
		}, {
			"name": "(GMT-04:00) La Paz",
			"timezone": "America/La_Paz"
		}, {
			"name": "(GMT-04:00) Santiago",
			"timezone": "America/Santiago"
		}, {
			"name": "(GMT-03:30) Newfoundland",
			"timezone": "Canada/Newfoundland"
		}, {
			"name": "(GMT-03:00) Buenos Aires",
			"timezone": "America/Buenos_Aires"
		}, {
			"name": "(GMT-03:00) Greenland",
			"timezone": "Greenland"
		}, {
			"name": "(GMT-02:00) Stanley",
			"timezone": "Atlantic/Stanley"
		}, {
			"name": "(GMT-01:00) Azores",
			"timezone": "Atlantic/Azores"
		}, {
			"name": "(GMT-01:00) Cape Verde Is.",
			"timezone": "Atlantic/Cape_Verde"
		}, {
			"name": "(GMT) Casablanca",
			"timezone": "Africa/Casablanca"
		}, {
			"name": "(GMT) Dublin",
			"timezone": "Europe/Dublin"
		}, {
			"name": "(GMT) Lisbon",
			"timezone": "Europe/Lisbon"
		}, {
			"name": "(GMT) London",
			"timezone": "Europe/London"
		}, {
			"name": "(GMT) Monrovia",
			"timezone": "Africa/Monrovia"
		}, {
			"name": "(GMT+01:00) Amsterdam",
			"timezone": "Europe/Amsterdam"
		}, {
			"name": "(GMT+01:00) Belgrade",
			"timezone": "Europe/Belgrade"
		}, {
			"name": "(GMT+01:00) Berlin",
			"timezone": "Europe/Berlin"
		}, {
			"name": "(GMT+01:00) Bratislava",
			"timezone": "Europe/Bratislava"
		}, {
			"name": "(GMT+01:00) Brussels",
			"timezone": "Europe/Brussels"
		}, {
			"name": "(GMT+01:00) Budapest",
			"timezone": "Europe/Budapest"
		}, {
			"name": "(GMT+01:00) Copenhagen",
			"timezone": "Europe/Copenhagen"
		}, {
			"name": "(GMT+01:00) Ljubljana",
			"timezone": "Europe/Ljubljana"
		}, {
			"name": "(GMT+01:00) Madrid",
			"timezone": "Europe/Madrid"
		}, {
			"name": "(GMT+01:00) Paris",
			"timezone": "Europe/Paris"
		}, {
			"name": "(GMT+01:00) Prague",
			"timezone": "Europe/Prague"
		}, {
			"name": "(GMT+01:00) Rome",
			"timezone": "Europe/Rome"
		}, {
			"name": "(GMT+01:00) Sarajevo",
			"timezone": "Europe/Sarajevo"
		}, {
			"name": "(GMT+01:00) Skopje",
			"timezone": "Europe/Skopje"
		}, {
			"name": "(GMT+01:00) Stockholm",
			"timezone": "Europe/Stockholm"
		}, {
			"name": "(GMT+01:00) Vienna",
			"timezone": "Europe/Vienna"
		}, {
			"name": "(GMT+01:00) Warsaw",
			"timezone": "Europe/Warsaw"
		}, {
			"name": "(GMT+01:00) Zagreb",
			"timezone": "Europe/Zagreb"
		}, {
			"name": "(GMT+02:00) Athens",
			"timezone": "Europe/Athens"
		}, {
			"name": "(GMT+02:00) Bucharest",
			"timezone": "Europe/Bucharest"
		}, {
			"name": "(GMT+02:00) Cairo",
			"timezone": "Africa/Cairo"
		}, {
			"name": "(GMT+02:00) Harare",
			"timezone": "Africa/Harare"
		}, {
			"name": "(GMT+02:00) Helsinki",
			"timezone": "Europe/Helsinki"
		}, {
			"name": "(GMT+02:00) Istanbul",
			"timezone": "Europe/Istanbul"
		}, {
			"name": "(GMT+02:00) Jerusalem",
			"timezone": "Asia/Jerusalem"
		}, {
			"name": "(GMT+02:00) Kyiv",
			"timezone": "Europe/Kiev"
		}, {
			"name": "(GMT+02:00) Minsk",
			"timezone": "Europe/Minsk"
		}, {
			"name": "(GMT+02:00) Riga",
			"timezone": "Europe/Riga"
		}, {
			"name": "(GMT+02:00) Sofia",
			"timezone": "Europe/Sofia"
		}, {
			"name": "(GMT+02:00) Tallinn",
			"timezone": "Europe/Tallinn"
		}, {
			"name": "(GMT+02:00) Vilnius",
			"timezone": "Europe/Vilnius"
		}, {
			"name": "(GMT+03:00) Baghdad",
			"timezone": "Asia/Baghdad"
		}, {
			"name": "(GMT+03:00) Kuwait",
			"timezone": "Asia/Kuwait"
		}, {
			"name": "(GMT+03:00) Nairobi",
			"timezone": "Africa/Nairobi"
		}, {
			"name": "(GMT+03:00) Riyadh",
			"timezone": "Asia/Riyadh"
		}, {
			"name": "(GMT+03:00) Moscow",
			"timezone": "Europe/Moscow"
		}, {
			"name": "(GMT+03:30) Tehran",
			"timezone": "Asia/Tehran"
		}, {
			"name": "(GMT+04:00) Baku",
			"timezone": "Asia/Baku"
		}, {
			"name": "(GMT+04:00) Volgograd",
			"timezone": "Europe/Volgograd"
		}, {
			"name": "(GMT+04:00) Muscat",
			"timezone": "Asia/Muscat"
		}, {
			"name": "(GMT+04:00) Tbilisi",
			"timezone": "Asia/Tbilisi"
		}, {
			"name": "(GMT+04:00) Yerevan",
			"timezone": "Asia/Yerevan"
		}, {
			"name": "(GMT+04:30) Kabul",
			"timezone": "Asia/Kabul"
		}, {
			"name": "(GMT+05:00) Karachi",
			"timezone": "Asia/Karachi"
		}, {
			"name": "(GMT+05:00) Tashkent",
			"timezone": "Asia/Tashkent"
		}, {
			"name": "(GMT+05:30) Kolkata",
			"timezone": "Asia/Kolkata"
		}, {
			"name": "(GMT+05:45) Kathmandu",
			"timezone": "Asia/Kathmandu"
		}, {
			"name": "(GMT+06:00) Ekaterinburg",
			"timezone": "Asia/Yekaterinburg"
		}, {
			"name": "(GMT+06:00) Almaty",
			"timezone": "Asia/Almaty"
		}, {
			"name": "(GMT+06:00) Dhaka",
			"timezone": "Asia/Dhaka"
		}, {
			"name": "(GMT+07:00) Novosibirsk",
			"timezone": "Asia/Novosibirsk"
		}, {
			"name": "(GMT+07:00) Bangkok",
			"timezone": "Asia/Bangkok"
		}, {
			"name": "(GMT+07:00) Jakarta",
			"timezone": "Asia/Jakarta"
		}, {
			"name": "(GMT+08:00) Krasnoyarsk",
			"timezone": "Asia/Krasnoyarsk"
		}, {
			"name": "(GMT+08:00) Chongqing",
			"timezone": "Asia/Chongqing"
		}, {
			"name": "(GMT+08:00) Hong Kong",
			"timezone": "Asia/Hong_Kong"
		}, {
			"name": "(GMT+08:00) Kuala Lumpur",
			"timezone": "Asia/Kuala_Lumpur"
		}, {
			"name": "(GMT+08:00) Perth",
			"timezone": "Australia/Perth"
		}, {
			"name": "(GMT+08:00) Singapore",
			"timezone": "Asia/Singapore"
		}, {
			"name": "(GMT+08:00) Taipei",
			"timezone": "Asia/Taipei"
		}, {
			"name": "(GMT+08:00) Ulaan Bataar",
			"timezone": "Asia/Ulaanbaatar"
		}, {
			"name": "(GMT+08:00) Urumqi",
			"timezone": "Asia/Urumqi"
		}, {
			"name": "(GMT+09:00) Irkutsk",
			"timezone": "Asia/Irkutsk"
		}, {
			"name": "(GMT+09:00) Seoul",
			"timezone": "Asia/Seoul"
		}, {
			"name": "(GMT+09:00) Tokyo",
			"timezone": "Asia/Tokyo"
		}, {
			"name": "(GMT+09:30) Adelaide",
			"timezone": "Australia/Adelaide"
		}, {
			"name": "(GMT+09:30) Darwin",
			"timezone": "Australia/Darwin"
		}, {
			"name": "(GMT+10:00) Yakutsk",
			"timezone": "Asia/Yakutsk"
		}, {
			"name": "(GMT+10:00) Brisbane",
			"timezone": "Australia/Brisbane"
		}, {
			"name": "(GMT+10:00) Canberra",
			"timezone": "Australia/Canberra"
		}, {
			"name": "(GMT+10:00) Guam",
			"timezone": "Pacific/Guam"
		}, {
			"name": "(GMT+10:00) Hobart",
			"timezone": "Australia/Hobart"
		}, {
			"name": "(GMT+10:00) Melbourne",
			"timezone": "Australia/Melbourne"
		}, {
			"name": "(GMT+10:00) Port Moresby",
			"timezone": "Pacific/Port_Moresby"
		}, {
			"name": "(GMT+10:00) Sydney",
			"timezone": "Australia/Sydney"
		}, {
			"name": "(GMT+11:00) Vladivostok",
			"timezone": "Asia/Vladivostok"
		}, {
			"name": "(GMT+12:00) Magadan",
			"timezone": "Asia/Magadan"
		}, {
			"name": "(GMT+12:00) Auckland",
			"timezone": "Pacific/Auckland"
		}, {
			"name": "(GMT+12:00) Fiji",
			"timezone": "Pacific/Fiji"
		}],
	days: [
		{
			text: 'monday',
			value: 'monday',
			index: 0
		},
		{
			text: 'tuesday',
			value: 'tuesday',
			index: 1
		},
		{
			text: 'wednesday',
			value: 'wednesday',
			index: 2
		},
		{
			text: 'thursday',
			value: 'thursday',
			index: 3
		},
		{
			text: 'friday',
			value: 'friday',
			index: 4
		},
		{
			text: 'saturday',
			value: 'saturday',
			index: 5
		},
		{
			text: 'sunday',
			value: 'sunday',
			index: 6
		},
	],
	months: [
		{
			text: 'january',
			value: 1,
			sort: 'jan'
		},
		{
			text: 'february',
			value: 2,
			sort: 'feb'
		},
		{
			text: 'march',
			value: 3,
			sort: 'mar'
		},
		{
			text: 'april',
			value: 4,
			sort: 'apr'
		},
		{
			text: 'may',
			value: 5,
			sort: 'maySort'
		},
		{
			text: 'june',
			value: 6,
			sort: 'jun'
		},
		{
			text: 'july',
			value: 7,
			sort: 'jul'
		},
		{
			text: 'august',
			value: 8,
			sort: 'aug'
		},
		{
			text: 'september',
			value: 9,
			sort: 'sep'
		},
		{
			text: 'october',
			value: 10,
			sort: 'oct'
		},
		{
			text: 'november',
			value: 11,
			sort: 'nov'
		},
		{
			text: 'december',
			value: 12,
			sort: 'dec'
		},

	],
	// for  bank testing
	socket: [],
	daysInWeek: [
		{
			text: 'sunday',
			value: 'sunday',
			index: 0
		},
		{
			text: 'monday',
			value: 'monday',
			index: 1
		},
		{
			text: 'tuesday',
			value: 'tuesday',
			index: 2
		},
		{
			text: 'wednesday',
			value: 'wednesday',
			index: 3
		},
		{
			text: 'thursday',
			value: 'thursday',
			index: 4
		},
		{
			text: 'friday',
			value: 'friday',
			index: 5
		},
		{
			text: 'saturday',
			value: 'saturday',
			index: 6
		},
	],
})
// Mutations
export const mutations = {
	// for bank testing
	setSocket(state, type) {
		state.socket.push(type)
	}
}