// State
export const state = () => ({
	deposits: [],
	depositsTotal: 0,
	searchedDeposits: [],
	searchedDepositsTotal: 0,
	depositFilter: {}
})
export const mutations = {
	setDeposits(state, data) {
		state.deposits = data
	},
	setSearchedDeposits(state, data) {
		state.searchedDeposits = data
	},
	setDepositsTotal(state, data) {
		state.depositsTotal = data
	},
	setSearchedBetTotal(state, data) {
		state.searchedDepositsTotal = data
	},
	depositFilter(state, data) {
		state.depositFilter = data
	}
}