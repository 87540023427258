// State
export const state = () => ({
	bets: [],
	betsTotal: 0,
	searchedBets: [],
	searchedBetsTotal: 0,
	playerBetFilter: {},
	betFilter: {}
})
export const mutations = {
	setBets(state, data) {
		state.bets = data
	},
	setSearchedBets(state, data) {
		state.searchedBets = data
	},
	setBetsTotal(state, data) {
		state.betsTotal = data
	},
	setSearchedBetTotal(state, data) {
		state.searchedBetsTotal = data
	},
	setPlayerBetFilter(state, data) {
		state.playerBetFilter = data
	},
	betFilter(state, data) {
		state.betFilter = data
	}
}