// State
export const state = () => ({
	playerFilters: {},
	playerFiltersInHours: {},
	amountFilter: {},
	amountFilterInHours: {}
})
export const mutations = {
	setPlayerFilters(state, data) {
		state.playerFilters = data
	},
	setPlayerFiltersInHours(state, data) {
		state.playerFiltersInHours = data
	},
	setAmountFilter(state, data) {
		state.amountFilter = data
	},
	setAmountFilterInHours(state, data) {
		state.amountFilterInHours = data
	},
}