// State
export const state = () => ({
	players: [],
	playersTotal: 0,
	searchedPlayers: [],
	searchedPlayersTotal: 0,
	playerFilter: {}
})
export const mutations = {
	setPlayers(state, data) {
		state.players = data
	},
	setSearchedPlayers(state, data) {
		state.searchedPlayers = data
	},
	setPlayersTotal(state, data) {
		state.playersTotal = data
	},
	setSearchedPlayerTotal(state, data) {
		state.searchedPlayersTotal = data
	},
	playerFilter(state, data) {
		state.playerFilter = data
	}
}