// State
export const state = () => ({
	loading: false,
	transactionStatus: [
		{ value: '', text: 'aLL' },
		{ value: 1, text: 'created' },
		{ value: 2, text: 'confirmed' },
		{ value: 3, text: 'locked' },
		{ value: 4, text: 'success' },
		{ value: 5, text: 'rejectedByAdmin' },
		{ value: 6, text: 'cancelled' },
	],
	withdrawalStatus: [
		{ value: '', text: 'aLL' },
		{ value: 1, text: 'created' },
		{ value: 2, text: 'locked' },
		{ value: 3, text: 'rejectedByAdmin' },
		{ value: 4, text: 'success' },
		{ value: 5, text: 'declinedByPlayer' },
	],
	depositStatus: [
		{ value: 2, text: 'Confirmed' },
		{ value: 3, text: 'Locked' },
		{ value: 4, text: 'Success' },
	],
	deposits: [],
	newDeposits: [],
	newDepositsForNotification: [],
	pendingDeposits: [],
	depositDetail: {},
	depositDetailImage: {},
	withdrawals: [],
	newWithdrawals: [],
	newWithdrawalsForNotification: [],
	pendingWithdrawals: [],
	withdrawalDetail: {},

	// Not used anywhere - Only for information purpose.
	status: {
		1: { text: 'created', color: 'secondary' },
		2: { text: 'confirmed', color: 'primary' },
		3: { text: 'locked', color: 'warning' },
		4: { text: 'success', color: 'success' },
		5: { text: 'failed', color: 'error' },
	},

	banks: {},
	bank: {},
	// companyBankAccounts: {},
	turnoverHistory: {},

	// cancel token
	financeCancelToken: {},
	// For deposit socket purposes only, save the current filter
	depositFilter: {},
	withdrawalFilter: {},
	companyThirdPartyPaymentGateways: {},
	companyThirdPartyPaymentGatewaysLogs: {},
	companyThirdPartyPaymentGateway: {},
	exportDepositToExcel: [],
	exportWithdrawalToExcel: [],
	paymentProviders: [],
	providerNameList: []
})

// Actions
export const actions = {
	// ? Deposits
	// Get All
	async deposits({ commit, state }, data) {
		if (state.financeCancelToken.deposits) {
			state.financeCancelToken.deposits.cancel('')
		}
		commit('setFinanceCancelToken', { deposits: this.$axios.CancelToken.source() })
		const params = { ...data }
		if (params.currency === 'ALL') delete params.currency
		if (params.apply_bonus === '') delete params.apply_bonus

		if (params?.deposit_status === '') {
			params.deposit_status = JSON.stringify([4, 5, 6])
			// params.deposit_status = null
		} else if (params?.deposit_status) {
			params.deposit_status =
				typeof params.deposit_status === 'string'
					? params.deposit_status
					: JSON.stringify([params.deposit_status])
		} else {
			params.deposit_status = JSON.stringify([4, 5, 6])
		}
		if (!params.start_date || !params.end_date) {
			return
		}

		// for socket purposes, commit for filter
		commit('setDepositFilter', params)

		await this.$axios
			.get('/finances/deposits', {
				params,
				cancelToken: state.financeCancelToken.deposits.token
			})
			.then((response) => {
				commit('setDeposits', response.data)
				params.deposit_status = JSON.stringify([4, 5, 6])
			})
			.catch(() => false)
	},
	async newDeposits({ commit, state }, data) {
		// One request is for display in notifications
		if (data.for_notifications) {
			if (state.financeCancelToken.newDepositsForNotification) {
				state.financeCancelToken.newDepositsForNotification.cancel('')
			}
		} else if (state.financeCancelToken.newDeposits) {
			state.financeCancelToken.newDeposits.cancel('')
		}
		if (data.for_notifications) {
			commit('setFinanceCancelToken', { newDepositsForNotification: this.$axios.CancelToken.source() })
		} else {
			commit('setFinanceCancelToken', { newDeposits: this.$axios.CancelToken.source() })
		}

		const params = JSON.parse(JSON.stringify(data))
		delete params.for_notifications
		if (params.currency === 'ALL') delete params.currency

		if (params?.deposit_status) {
			params.deposit_status =
				params.deposit_status === 2 || params.deposit_status === 1
					? JSON.stringify([params.deposit_status])
					: JSON.stringify([2])
		} else {
			params.deposit_status = JSON.stringify([2])
		}
		await this.$axios
			.get('/finances/deposits', {
				params,
				cancelToken: data.for_notifications ? state.financeCancelToken.newDepositsForNotification.token : state.financeCancelToken.newDeposits.token
			})
			.then((response) => {
				if (data.for_notifications) {
					commit('setNewDepositsForNotification', response.data)
				} else {
					commit('setNewDeposits', response.data)
				}
				params.deposit_status = null
				data.deposit_status = null
			})
			.catch(() => false)
	},

	// ? pending status
	async pendingDeposits({ commit, state }, data) {
		if (state.financeCancelToken.pendingDeposits) {
			state.financeCancelToken.pendingDeposits.cancel('')
		}
		commit('setFinanceCancelToken', { pendingDeposits: this.$axios.CancelToken.source() })
		const params = data
		if (params.currency === 'ALL') delete params.currency
		if (params.apply_bonus === '') delete params.apply_bonus

		if (params?.deposit_status) {
			params.deposit_status =
				params.deposit_status === 3
					? JSON.stringify([params.deposit_status])
					: JSON.stringify([3])
		} else {
			params.deposit_status = JSON.stringify([3])
		}
		await this.$axios
			.get('/finances/deposits', {
				params,
				cancelToken: state.financeCancelToken.pendingDeposits.token
			})
			.then((response) => {
				commit('setPendingDeposits', response.data)
				params.deposit_status = null
			})
			.catch(() => false)
	},

	// Create
	async createDeposit({ commit, rootGetters }, form) {
		commit('loading', true)
		await this.$axios
			.post('/finances/deposits', form)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('depositCreatedSuccessful'))
				this.$router.push('/finance/deposits')
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// Lock
	async lockDeposit({ commit, rootGetters }, id) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/deposits/lock/${id}`)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('depositLocked'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// UnLock
	async unlockDeposit({ commit, rootGetters }, id) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/deposits/unlock/${id}`)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('depositUnlocked'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// Confirm
	async confirmDeposit({ commit, rootGetters }, params) {
		commit('loading', true)
		const id = params.id;
		delete params.id;
		const response = await this.$axios
			.put(`/finances/deposits/confirm/${id}`, params)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('depositConfirmed'))
				commit('loading', false)
				return true;
			})
			.catch(() => commit('loading', false))
		return response;
	},
	// Decline
	async declineDeposit({ commit, rootGetters }, form) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/deposits/decline/${form.id}`, { remarks: form.remarks })
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('depositDeclined'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},

	// ? Withdrawals
	// Get All
	async withdrawals({ commit, state }, data) {
		if (state.financeCancelToken.withdrawals) {
			state.financeCancelToken.withdrawals.cancel('')
		}
		commit('setFinanceCancelToken', { withdrawals: this.$axios.CancelToken.source() })
		const params = data
		if (params.currency === 'ALL') {
			delete params.currency
		}
		if (params.apply_bonus === '') delete params.apply_bonus

		if (params?.withdrawal_status === '') {
			params.withdrawal_status = JSON.stringify([3, 4, 5])
		} else if (params?.withdrawal_status) {
			params.withdrawal_status =
				typeof params.withdrawal_status === 'string'
					? params.withdrawal_status
					: JSON.stringify([params.withdrawal_status])
		} else {
			params.withdrawal_status = JSON.stringify([3, 4, 5])
		}
		commit('setWithdrawalFilter', params)
		await this.$axios
			.get('/finances/withdrawals', {
				params,
				cancelToken: state.financeCancelToken.withdrawals.token
			})
			.then((response) => {
				commit('setWithdrawals', response.data)
			})
			.catch(() => false)
	},

	async newWithdrawals({ commit, state }, data) {
		// One request is for display in notifications
		if (data.for_notifications) {
			if (state.financeCancelToken.newWithdrawalsForNotification) {
				state.financeCancelToken.newWithdrawalsForNotification.cancel('')
			}
		} else if (state.financeCancelToken.newWithdrawals) {
			state.financeCancelToken.newWithdrawals.cancel('')
		}
		if (data.for_notifications) {
			commit('setFinanceCancelToken', { newWithdrawalsForNotification: this.$axios.CancelToken.source() })
		} else {
			commit('setFinanceCancelToken', { newWithdrawals: this.$axios.CancelToken.source() })
		}
		const params = JSON.parse(JSON.stringify(data))
		if (params.currency === 'ALL') {
			delete params.currency
		}
		if (params.apply_bonus === '') delete params.apply_bonus

		if (params?.withdrawal_status === '') {
			params.withdrawal_status = JSON.stringify([1])
		} else if (params?.withdrawal_status) {
			params.withdrawal_status =
				typeof params.withdrawal_status === 'string'
					? params.withdrawal_status
					: JSON.stringify([params.withdrawal_status])
		} else {
			params.withdrawal_status = JSON.stringify([1])
		}

		await this.$axios
			.get('/finances/withdrawals', {
				params,
				cancelToken: data.for_notifications ? state.financeCancelToken.newWithdrawalsForNotification.token : state.financeCancelToken.newWithdrawals.token
			})
			.then((response) => {
				if (data.for_notifications) {
					commit('newWithdrawalsForNotification', response.data)
				} else {
					commit('newWithdrawals', response.data)
				}
			})
			.catch(() => false)
	},
	async pendingWithdrawals({ commit, state }, data) {
		if (state.financeCancelToken.pendingWithdrawals) {
			state.financeCancelToken.pendingWithdrawals.cancel('')
		}
		commit('setFinanceCancelToken', { pendingWithdrawals: this.$axios.CancelToken.source() })
		const params = data
		if (params.currency === 'ALL') {
			delete params.currency
		}
		if (params.apply_bonus === '') delete params.apply_bonus

		if (params?.withdrawal_status === '') {
			params.withdrawal_status = JSON.stringify([2, 6])
		} else if (params?.withdrawal_status) {
			params.withdrawal_status =
				typeof params.withdrawal_status === 'string'
					? params.withdrawal_status
					: JSON.stringify([params.withdrawal_status])
		} else {
			params.withdrawal_status = JSON.stringify([2, 6])
		}

		await this.$axios
			.get('/finances/withdrawals', {
				params,
				cancelToken: state.financeCancelToken.pendingWithdrawals.token
			})
			.then((response) => {
				commit('pendingWithdrawals', response.data)
			})
			.catch(() => false)
	},

	// Lock
	async lockWithdrawals({ commit, rootGetters }, id) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/withdrawals/lock/${id}`)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalLocked'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// Lock
	async unlockWithdrawals({ commit, rootGetters }, id) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/withdrawals/unlock/${id}`)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalUnlocked'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// reject
	async rejectWithdrawals({ commit, rootGetters }, form) {
		commit('loading', true)
		await this.$axios
			.put(`/finances/withdrawals/decline/${form.id}`, { remarks: form.remarks })
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalDecline'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// confirm
	async confirmWithdrawals({ commit, rootGetters }, form) {
		commit('loading', true)
		return await this.$axios
			.put(`/finances/withdrawals/confirm/${form.id}`, form.body)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalConfirmed'))
				commit('loading', false)
				return true;
			})
			.catch(() => {
				commit('loading', false);
				return false;
			});
	},
	async confirmCryptoWithdrawals({ commit, rootGetters }, form) {
		commit('loading', true)
		return await this.$axios
			.put(`/finances/withdrawals/crypto/confirm/${form.id}`, form.body)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalConfirmed'))
				commit('loading', false)
				return true
			})
			.catch(() => {
				commit('loading', false);
				return false
			})
	},
	async confirmThirdPartyWithdrawals({ commit, rootGetters }, form) {
		commit('loading', true)
		return await this.$axios
			.put(`/finances/withdrawals/third-party/confirm/${form.id}`, form.body)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('withdrawalConfirmed'))
				commit('loading', false)
				return true
			})
			.catch(() => {
				commit('loading', false);
				return false
			})
	},

	// ? Banks
	// Get All
	async banks({ commit, state }, params) {
		commit('setFinanceCancelToken', { banks: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get('/bank-list', {
				params,
				cancelToken: state.financeCancelToken.banks.token
			})
			.then((response) => {
				commit('banks', response.data)
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},
	// Get Single bank
	async bank({ commit, state }, id) {
		commit('setFinanceCancelToken', { bank: this.$axios.CancelToken.source() })
		await this.$axios
			.get('/bank-list', {
				params: {
					bank_id: id,
				},
				cancelToken: state.financeCancelToken.bank.token
			})
			.then((response) => {
				commit('bank', response.data.data[0])
			})
			.catch(() => false)
	},
	// Create bank
	async createBank({ commit, rootGetters }, params) {
		commit('loading', true)
		const formData = new FormData()
		if (params.image) {
			formData.append('image', params.image)
		}
		formData.append('bank_name', params.bank_name)
		formData.append('bank_url', params.bank_url)
		formData.append('remarks', params.remarks)
		formData.append('is_popular', params.is_popular)
		formData.append('status', params.status)

		await this.$axios
			.post('/bank-list', formData)
			.then((response) => {
				this.$toast.success(rootGetters['master/storeTl']('bankHasBeenCreated'))
				this.$router.push('/finance/banks')
			})
			.catch(() => false)
		commit('loading', false)
	},
	// Update Bank
	// Create bank
	async editBank({ commit, rootGetters }, params) {
		commit('loading', true)
		const formData = new FormData()
		if (params.image) {
			formData.append('image', params.image)
		}
		formData.append('bank_name', params.bank_name)
		formData.append('bank_url', params.bank_url)
		formData.append('remarks', params.remarks)
		formData.append('is_popular', params.is_popular)
		formData.append('status', params.status)

		await this.$axios
			.put(`/bank-list/${this.$router.currentRoute.params.id}`, formData)
			.then((response) => {
				this.$toast.success(rootGetters['master/storeTl']('bankHasBeenUpdated'))
				this.$router.push('/finance/banks')
			})
			.catch(() => false)
		commit('loading', false)
	},
	// Delete
	async bankDelete({ commit, rootGetters }, id) {
		commit('loading', true)
		await this.$axios
			.delete(`/bank-list/${id}`)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('bankHasBeenDeleted'))
				commit('loading', false)
			})
			.catch(() => commit('loading', false))
	},

	// manual deposit
	async createManualDeposit({ commit, rootGetters }, form) {
		commit('loading', true)
		const response = await this.$axios
			.post('/finances/deposits/create-manual-deposit', form)
			.then((response) => {
				if (typeof response.data.message === 'string') {
					this.$toast.success(rootGetters['master/storeTl'](response.data.message))
				} else {
					this.$toast.success(response.data.message)
				}
				this.$router.go(-1);
				commit('loading', false)
				return true
			})
			.catch(() => {
				commit('loading', false)
				return false
			})
		return response
	},
	// manual deposit
	async createManualDepositLao({ commit, rootGetters }, form) {
		commit('loading', true)
		const response = await this.$axios
			.post('/finances/deposits/create-manual-deposit-with-exchange', form)
			.then((response) => {
				if (typeof response.data.message === 'string') {
					this.$toast.success(rootGetters['master/storeTl'](response.data.message))
				} else {
					this.$toast.success(response.data.message)
				}
				this.$router.go(-1);
				commit('loading', false)
				return true
			})
			.catch(() => {
				commit('loading', false)
				return false
			})
		return response
	},

	// Third party company bank accounts apis
	async createCompanyThirdPartyPaymentGateway({ _ }, form) {
		const response = await this.$axios
			.post('/finances/company-third-party-payment-gate-way', form)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async companyThirdPartyPaymentGateway({ commit }, params) {
		commit('setFinanceCancelToken', { companyThirdPartyPaymentGateway: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get('/finances/company-third-party-payment-gate-way', { params })
			.then((response) => {
				commit('setCompanyThirdPartyPaymentGateways', response.data)
			})
			.catch(() => {
				return false
			})
		return response
	},
	async companyThirdPartyPaymentGatewayLogs({ commit }, params) {
		const response = await this.$axios
			.get('/finances/company-third-party-payment-gate-way/account/logs', { params })
			.then((response) => {
				commit('setCompanyThirdPartyPaymentGatewaysLogs', response.data)
			})
			.catch(() => {
				return false
			})
		return response
	},
	async singleCompanyThirdPartyPaymentGateway({ commit }, id) {
		const response = await this.$axios
			.get(`/finances/company-third-party-payment-gate-way/${id}`)
			.then((response) => {
				commit('setCompanyThirdPartyPaymentGateway', response.data)
			})
			.catch(() => {
				return false
			})
		return response
	},
	async deleteCompanyThirdPartyPaymentGateway({ commit }, form) {
		const response = await this.$axios
			.delete(`/finances/company-third-party-payment-gate-way/${form.id}`, {
				data: form.data
			})
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async updateCompanyThirdPartyPaymentGateway({ commit }, form) {
		const response = await this.$axios
			.put(`/finances/company-third-party-payment-gate-way/${form.id}`, form.data)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async updateCompanyThirdPartyPaymentGatewayStatus({ commit }, form) {
		const response = await this.$axios
			.put(`/finances/company-third-party-payment-gate-way/status/${form.id}`, form.data)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async updateCompanyThirdPartyPaymentGatewayThreshold({ commit }, form) {
		const response = await this.$axios
			.put(`/finances/company-third-party-payment-gate-way/threshold/${form.id}`, form.data)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async exportDepositToExcel({ commit }, params) {
		await this.$axios
			.get('/finances/deposits/export/excel', {
				params
			})
			.then((response) => {
				commit('exportDepositToExcel', response.data)
			})
			.catch(() => {
				commit('exportDepositToExcel', [])

				return false
			})
	},
	async exportWithdrawalToExcel({ commit }, params) {
		await this.$axios
			.get('/finances/withdrawals/export/excel', {
				params
			})
			.then((response) => {
				commit('exportWithdrawalToExcel', response.data)
			})
			.catch(() => {
				commit('exportWithdrawalToExcel', [])

				return false
			})
	},
	async getPaymentProvider({ commit }, params) {
		await setTimeout(() => {
			commit('setPaymentProviders', [
				{
					id: 1,
					sequence: 1,
				},
				{
					id: 2,
					sequence: 2,
				},
			])
		}, 1000);
		// await this.$axios
		// 	.get('', {
		// 		params
		// 	})
		// 	.then((response) => {
		// 		commit('setPaymentProviders', response.data)
		// 	})
		// 	.catch(() => {
		// 		return false
		// 	})
	},
	async getProviderNameList({ commit }, params) {
		await setTimeout(() => {
			commit('setProviderNameList', [
				{
					id: 1,
					text: 'GT Pay',
				},
				{
					id: 2,
					text: 'AMB Pay',
				},
			])
		}, 1000);
		// await this.$axios
		// 	.get('', {
		// 		params
		// 	})
		// 	.then((response) => {
		// 		commit('setPaymentProviders', response.data)
		// 	})
		// 	.catch(() => {
		// 		return false
		// 	})
	},

	async deletePaymentProvider({ commit }, id) {
		return await true;
		// await this.$axios
		// 	.delete('', {
		// 		params
		// 	})
		// 	.then((response) => {
		// 		return true
		// 	})
		// 	.catch(() => {
		// 		return false
		// 	})
	},

}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setDeposits(state, data) {
		state.deposits = data
	},
	setWithdrawals(state, data) {
		state.withdrawals = data
	},
	newWithdrawals(state, data) {
		state.newWithdrawals = data
	},
	newWithdrawalsForNotification(state, data) {
		state.newWithdrawalsForNotification = data
	},
	pendingWithdrawals(state, data) {
		state.pendingWithdrawals = data
	},
	setWithdrawalDetail(state, data) {
		state.withdrawalDetail = data
	},
	banks(state, data) {
		state.banks = data
	},
	bank(state, data) {
		state.bank = data
	},
	setNewDeposits(state, data) {
		state.newDeposits = data
	},
	setNewDepositsForNotification(state, data) {
		state.newDepositsForNotification = data
	},
	setPendingDeposits(state, data) {
		state.pendingDeposits = data
	},
	setDepositDetail(state, data) {
		state.depositDetail = data
	},
	depositDetailImage(state, data) {
		state.depositDetailImage = data
	},
	turnoverHistory(state, data) {
		state.turnoverHistory = data
	},
	setDepositFilter(state, data) {
		state.depositFilter = data
	},
	setWithdrawalFilter(state, data) {
		state.withdrawalFilter = data
	},
	setCompanyThirdPartyPaymentGateways(state, data) {
		state.companyThirdPartyPaymentGateways = data
	},
	setCompanyThirdPartyPaymentGatewaysLogs(state, data) {
		state.companyThirdPartyPaymentGatewaysLogs = data
	},
	setCompanyThirdPartyPaymentGateway(state, data) {
		state.companyThirdPartyPaymentGateway = data
	},
	exportDepositToExcel(state, data) {
		state.exportDepositToExcel = data
	},
	exportWithdrawalToExcel(state, data) {
		state.exportWithdrawalToExcel = data
	},
	setPaymentProviders(state, data) {
		state.paymentProviders = data
	},
	setProviderNameList(state, data) {
		state.providerNameList = data
	},
	setFinanceCancelToken: (state, data) => {
		const oldData = { ...state.financeCancelToken }
		state.financeCancelToken = {
			...oldData, ...data
		}
	}
}

// Getters
export const getters = {}
