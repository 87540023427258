export const state = () => ({
	items: [],
	itemsTotal: 0,
	searchedItems: [],
	searchedItemsTotal: 0
})
export const mutations = {
	setItems(state, data) {
		state.items = data
	},
	setSearchedItems(state, data) {
		state.searchedItems = data
	},
	setItemsTotal(state, data) {
		state.itemsTotal = data
	},
	setSearchedItemsTotal(state, data) {
		state.searchedItemsTotal = data
	}
}