export const state = () => ({
	showDialog: false,
	dialogTitle: null,
	dialogSubtitle: null,
	color: null,
	type: 'success',
	confirmButtonFunc: null,
	confirmText: null,
	timeout: null,
	confirmButtonStatus: null, // For Confirm and Cancel Buttons
	icon: null,
	description: null
})

export const actions = {
	setShowDialog({ commit, }, { display, type, title, subtitle, func, color, confirmText, icon, description }) {
		commit('setShowDialog', { display, type, title, subtitle, func, color, confirmText, icon, description })
		if (display && type !== 'confirm') {
			// If the dialog is display, the dialog should be disappear after 2 seconds
			commit('setDialogTimeout', setTimeout(() => {
				commit('setShowDialog', { display: false })
			}, 1500)
			)
		}
	},
	// Clear timeout incase the dialog is closed before dialog is actually closed.
	clearDialogTimeout({ state }, _) {
		if (state.timeout) {
			clearTimeout(state.timeout)
		}
	}
}

export const mutations = {
	setShowDialog: (state, { display, type, title, subtitle, func, color, confirmText, icon, description }) => {
		if (display === true) {
			state.showDialog = display
			state.dialogTitle = title
			state.dialogSubtitle = subtitle
			state.type = type
			state.color = color
			state.confirmText = confirmText
			state.icon = icon
			state.description = description
			if (func) {
				state.confirmButtonFunc = func
			}
		} else {
			// Set default values for all related custom toaster states
			state.type = null
			state.showDialog = false
			state.dialogTitle = null
			state.dialogSubtitle = null
			state.color = null
			state.confirmText = null
			state.confirmButtonFunc = null
			state.icon = null
			state.description = null
		}
	},
	setDialogTimeout: (state, data) => {
		state.timeout = data
	},
	setConfirmButtonStatus: (state, data) => {
		state.confirmButtonStatus = data
	}
}