import axios from 'axios'
export const state = () => ({
	download: "",
})

export const actions = {
	async downloadExcelFile({ _ }, data) {
		return await axios.post(`/download-excel-file`, data, { responseType: 'blob' })
			.then((response) => {
				// console.log({ response: response.data })
				// Create a blob object from the response data
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

				// Create a link element and click it to trigger the download
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${data.fileName}.xlsx`); // Set desired file name here
				document.body.appendChild(link);
				link.click();

				// Clean up resources
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			}).catch(() => false)
	}
}
export const mutations = {

}