// State
export const state = () => ({
	allSummarySearchedFilter: {},
	dashFilter: {}
})
export const mutations = {
	setAllSummary(state, data) {
		state.allSummarySearchedFilter = { ...state.allSummarySearchedFilter, ...data }
	},
	dashFilter(state, data) {
		state.dashFilter = data
	}
}