// State
export const state = () => ({
	loading: false,
	providerLoading: false,
	gameLoading: false,
	games: [],
	gamesWithProviderId: [],
	game: {},
	types: [],
	providers: [],
	searchProviders: [],
	submenuGameProviderTemp: {},
	submenuGameNameTemp: {},


	// cancel token
	gameCancelToken: {}
})

// Actions
export const actions = {
	// * Games
	async games({ commit, state }, filters) {
		const data = JSON.parse(JSON.stringify(filters))
		commit('setGameCancelToken', { games: this.$axios.CancelToken.source() })
		let params = {}
		if (!data) {
			params = {
				is_active: 'all',
				is_maintenance: 'all',
			}
		} else {
			params = data;
			delete params.is_from_provider
		}
		try {
			commit('setGameLoading', true)
			const response = await this.$axios
				.get(`/provider-management/games`, {
					params,
					cancelToken: state.gameCancelToken.games.token
				})
				.then((response) => {
					if (filters.is_from_provider) {
						commit('gamesWithProviderId', response.data)
					} else {
						commit('games', response.data)
					}
					commit('setGameLoading', false)
					return response.data
				}).catch(() => {
					commit('setGameLoading', false)
					return []
				})
			return response
		} catch (e) {
			commit('setGameLoading', false)
			return []
		}
	},

	// * Providers
	async providers({ commit, state }, data) {
		commit('setGameCancelToken', { providers: this.$axios.CancelToken.source() })
		let params = {}
		if (!data) {
			params = {
				is_active: 'all',
				is_maintenance: 'all',
			}
		} else {
			params = data;
		}
		try {
			commit('setProviderLoading', true)
			await this.$axios
				.get(`/provider-management/providers`, { params, cancelToken: state.gameCancelToken.providers.token })
				.then((response) => {
					commit('providers', response.data)
					commit('setProviderLoading', false)
				}).catch(() => {
					commit('setProviderLoading', false)
				})
		} catch (e) {
			commit('setProviderLoading', false)
		}
	},

	// * types
	async types({ commit, state }, data) {
		if (state.types.length > 0) {
			commit('types', state.types)
			return;
		}
		commit('setGameCancelToken', { types: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			await this.$axios
				.get(`/game-types`, {
					params: data,
					cancelToken: state.gameCancelToken.types.token
				})
				.then((response) => {
					commit('types', response.data)
					commit('loading', false)
				}).catch(() => {
					commit('loading', false)
				})
		} catch (e) {
			commit('loading', false)
		}
	},
	searchProviders({ commit, state }) {
		if (state.searchProviders.length > 0) {
			commit('searchProviders', state.searchProviders)
			return;
		}
		commit('setGameCancelToken', { searchProviders: this.$axios.CancelToken.source() })
		this.$axios.get(`provider-management/providers`, {
			params: { is_active: 'all', is_maintenance: 'all', currency: 'THB' },
			cancelToken: state.gameCancelToken.searchProviders.token,
		})
			.then((response) => {
				commit("searchProviders", response.data)
			})
			.catch(() => {
				return null;
			})
	},

	async updateProviderED({ commit, rootGetters }, form) {
		commit('loading', true)
		let url = `/provider-management/providers/game-types/status/${form.id}`
		if (~~this.$cookie.get("wlType") === 1) {
			url = `/provider-management/providers/game-types/status/${form.id}/${form.currency}`
		}
		return await this.$axios
			.put(url, form.data)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('gameProviderSuccessfulUpdated'))
				commit('loading', false)
				return true;
			}).catch(() => {
				commit('loading', false)
				return false
			})
	},
	async updateProviderMaintenance({ commit, rootGetters }, form) {
		commit('loading', true)
		let url = `/provider-management/providers/maintenance/${form.id}`
		if (~~this.$cookie.get("wlType") === 1) {
			url = `/provider-management/providers/maintenance/${form.id}/${form.currency}`
		}
		return await this.$axios
			.put(url, form.data)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('gameProviderSuccessfulUpdated'))
				commit('loading', false)
				return true;
			}).catch(() => {
				commit('loading', false)
				return false
			})
	},
	async updateProviderIcon({ commit, rootGetters }, form) {
		const formData = new FormData()
		formData.append('icon', form.icon)
		commit('loading', true)
		return await this.$axios
			.put(`/provider-management/providers/icon/${form.id}`, formData)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('gameProviderSuccessfulUpdated'))
				commit('loading', false)
				return true;
			}).catch(() => {
				commit('loading', false)
				return false
			})
	},
	async updateProviderStatus({ commit }, form) {
		let url = `/provider-management/providers/status/${form.id}`
		if (~~this.$cookie.get("wlType") === 1) {
			url = `/provider-management/providers/status/${form.id}/${form.currency}`
		}
		const response = await this.$axios
			.put(url, form.data)
			.then(() => {
				return true;
			}).catch(() => {
				commit('loading', false)
				return false
			})
		return response
	},
	async onCancelMaintenanceTime({ commit }, data) {
		let url = `/provider-management/providers/maintenance/cancel/${data.id}`
		if (~~this.$cookie.get("wlType") === 1) {
			url = `/provider-management/providers/maintenance/cancel/${data.id}/${data.currency}`
		}
		const response = await this.$axios
			.put(url, data.body)
			.then(() => {
				return true;
			}).catch(() => {
				return false
			})
		return response
	}

}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	games(state, data) {
		state.games = data
	},
	gamesWithProviderId(state, data) {
		state.gamesWithProviderId = data
	},
	// game(state, data) {
	// 	state.game = data
	// },
	providers(state, data) {
		state.providers = data
	},
	types(state, data) {
		state.types = data
	},
	searchProviders(state, data) {
		state.searchProviders = data;
	},
	setProviderLoading(state, data) {
		state.providerLoading = data
	},
	setGameLoading(state, data) {
		state.gameLoading = data
	},
	setGameCancelToken: (state, data) => {
		const oldData = { ...state.gameCancelToken }
		state.gameCancelToken = {
			...oldData, ...data
		}
	},
	setSubmenuGameProviderTemp: (state, data) => {
		state.submenuGameProviderTemp = { ...state.submenuGameProviderTemp, ...data }
	},
	setSubmenuGameNameTemp: (state, data) => {
		state.submenuGameNameTemp = { ...state.submenuGameNameTemp, ...data }
	},
	setClearGameTemp: (state, data) => {
		state.submenuGameNameTemp = {}
		state.submenuGameProviderTemp = {}
	}
}

// Getters
export const getters = {
	gameTypeNameByCode: state => code => {
		const found = state.types.find(o => o.code === code)
		if (found) {
			return found.game_type_name
		}
	},
	filterGamesList: state => data => {
		if (!data.gameTypesId && !data.providerId && !data.searchText) return state.games || []

		const findProvider = state.providers.find(el => el.id === data.providerId) // FindProvider is for finding provider_id

		let providerId = null;

		if (findProvider) {
			providerId = findProvider.provider_id
		}
		const gamesFilter = (el) => {
			const checkProvider = (data.providerId && providerId) ? (el.gp_id === providerId) : true
			// ? Do not delete this line, in case we need game type in the future
			// const checkGameType = data.gameTypesId ? (parseInt(el.game_type || 0) === parseInt(data.gameTypesId)) : true
			return checkProvider
		}
		const gamesList = state.games.filter(gamesFilter)
		if (data.searchText) {
			const filterFunction = (el) => {
				if (!el.game_infos || el.game_infos.length === 0) return false // If the games doesn't have name, since some name is not existing in all games

				const gameName = el.game_infos[0].gameName

				if (!gameName) return false;
				if (gameName.toLowerCase().includes(data.searchText.toLowerCase())) return true;

				return false
			}
			return gamesList.filter(filterFunction).sort((a, b) => (a?.game_id > b?.game_id) ? 1 : ((b?.game_id > a?.game_id) ? -1 : 0))
		}
		return gamesList.sort((a, b) => (a?.game_id > b?.game_id) ? 1 : ((b?.game_id > a?.game_id) ? -1 : 0));
	},
	getGameTypes: state => code => {
		const data = state.types.find((t) => t.code === code)
		if (data?.name) return data.name;
		else return code; // '-'
	}
}
